import './PriceDetails.css';
import { Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useSelector } from 'react-redux';

const PriceDetails = () => {
    const isMobile = useMediaQuery('(max-width: 36em)');

    const appliedCoupon = useSelector(state => state.appliedCoupon);
    const coupon_code = appliedCoupon.coupon_code;
    const discount_percent = appliedCoupon.discount_percent;
    const isCouponApplied = coupon_code != null;

    const planDetails = useSelector(state => state.planDetails);
    let price_value = planDetails.price_value;
    const currency_symbol = planDetails.currency_symbol;
    const currency_code = planDetails.currency_code;

    let totalDiscount = null;
    let grandTotal = price_value;
    if(isCouponApplied){
        totalDiscount = (discount_percent / 100) * price_value;
        totalDiscount = totalDiscount.toFixed(2).toString();
        grandTotal -= totalDiscount;
        grandTotal = grandTotal.toFixed(2).toString();
    }

    price_value = price_value.toString();
    grandTotal = grandTotal.toString();

    return (
        <div className='price_details'>
            <div className="price_item">
                <Text className='price_title'  fz={isMobile?'xs':'sm'} fw={'normal'}>Subtotal</Text>
                <Text component='span' className='price' fz={isMobile?'xs':'sm'} fw={'normal'}>{currency_symbol}{price_value}</Text>
            </div>
            {isCouponApplied ? 
                <div className='price_item'>
                    <Text className='price_title coupon_title'  fz={isMobile?'xs':'sm'} fw={'normal'}>{`Coupon - (${coupon_code.toUpperCase()})`}</Text>
                    <Text component='span' className='price coupon_price' fz={isMobile?'xs':'sm'} fw={'normal'}>{`-${currency_symbol}${totalDiscount}`}</Text>
                </div>
            : null}
            <div className="price_item">
                <Text className='grand_total_title'  fz={isMobile?'xs':'sm'} fw={'normal'}>Grand Total</Text>
                <Text component='span' className='grand_total_price' fz={isMobile?'xs':'sm'} fw={'normal'}>{currency_symbol}{grandTotal} {currency_code}</Text>
            </div>
        </div>
    )
}

export default PriceDetails;