import './ServiceCard.css'
import { Text,Card } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

const ServiceCard = ({ title, description, image }) => {
    const isMobile = useMediaQuery('(max-width: 36em)');

    return (
        <Card shadow="sm" className='service_card'>
            <Text component='span' className='service_title'>{image}</Text>
            <Text size={isMobile?'md':'xl'} mt={'sm'}>{title}</Text>
            <Text size={isMobile?'sm':'md'} mb={'xs'} className='service_description'>{description}</Text>
        </Card>
    )
}

export default ServiceCard