import { Flex, Loader, Modal } from "@mantine/core"


const CustomLoader = ({children,opened,close}) => {
    return (
        <Modal opened={opened} onClose={close} withCloseButton={false} closeOnClickOutside={false} radius={'md'}>
            <Flex justify='center' align='center' gap={'md'}>
                <Loader variant="oval"/>
                {children}
            </Flex>
        </Modal>
    )
}


export default CustomLoader