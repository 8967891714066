import axios from ".";


export const getCheckoutDetails = async (id) => {
    const result = await axios.get(`/checkout/get_details?id=${id}`);
    return result.data;
}

export const createOrder = async (id,name,email,paymentMethod,couponId,coin=null) => {
    const result = await axios.post(`/payment/create_order`,{
        id,
        name,
        email,
        paymentMethod,
        couponId,
        coin
    })
    return result;
}

export const verifyPayment = async (id,orderId,paymentId,signature) => {
    const result = await axios.post(`/payment/verify`,{
        id,
        orderId,
        paymentId,
        signature
    })
    return result;
}


export const capturePayment = async (id,orderId) => {
    const result = await axios.post(`/payment/capture`,{
        id,
        orderId,
    })
    return result.data;
}

export const sendMessage = async (name,email,message,subject) => {
    const result = await axios.post(`/contact/send_message`,{
        name,
        email,
        message,
        subject
    })
    return result.data;
}


export const validateCoupon = async (couponCode, productId) => {
    const result = await axios.post(`/coupon/validate`,{
        couponCode,
        productId
    })
    return result.data;
}


export const createCheckoutSession = async (userId,planId,productId) => {
    const result = await axios.post(`/checkout/create`,{
        userId,
        planId,
        productId
    })
    return result.data;
}