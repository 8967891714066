import './Footer.css'
import { Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


const Footer = () => {
    const isMobile = useMediaQuery('(max-width: 36em)');
    const checkoutDetails = useSelector(state => state.checkoutDetails);
    const isHomePath = window.location.pathname === '/';

    return (
            <>
                {checkoutDetails.code === "SUCCESS" || isHomePath ?
                    <footer>
                    <div className="links">
                        <Link to="/terms">
                            <Text fz={isMobile?'xs':'sm'} fw={'normal'}>Terms of Service</Text>
                        </Link>
                        <Link to="/privacy-policy">
                            <Text fz={isMobile?'xs':'sm'} fw={'normal'}>Privacy Policy</Text>
                        </Link>
                        <Link to="/refund-policy">
                            <Text fz={isMobile?'xs':'sm'} fw={'normal'}>Refund Policy</Text>
                        </Link>
                        <Link to="/contact-us">
                            <Text fz={isMobile?'xs':'sm'} fw={'normal'}>Contact Us</Text>
                        </Link>
                    </div>
                    </footer>
                :null}
            </>
    )
}

export default Footer;