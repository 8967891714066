import './PrivacyPolicy.css'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Header from './Header';
import { Helmet } from 'react-helmet-async';

const PrivacyPolicy = () => {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className="privacy-policy-container">
            <Helmet>
                <title>Privacy Policy | BotsByAmit</title>
                <meta name="description" content="Privacy Policy | BotsByAmit" />
            </Helmet>
            <Header />
            <section className='privacy-policy'>
                <h1>Privacy Policy</h1>
                <p>This privacy policy outlines how we collect, use, and protect any personal information that you provide to us when using our website and our Telegram bots. We are committed to ensuring that your privacy is protected, and we comply with all applicable data protection laws.</p>

                <h3>1. Information we collect</h3>
                <div className='paragraph'>
                    We may collect the following information:
                    <ul>
                        <li>Name </li>
                        <li>Email address </li>
                        <li>Telegram chat ID </li>
                        <li>IP address (to determine the country of the user to display the price detail in their native currency) </li>
                        <li>Payment information (such as credit card information) through our payment gateway providers </li>
                        <li>Usage data, such as website analytics data collected by third-party services like Google Analytics </li>
                    </ul>
                </div>
                <p>We use this information to process your payment and provide you with the purchased premium features. We do not store your credit or debit card information, as it is securely encrypted through the Payment Card Industry Data Security Standard (PCI-DSS) when processing payment.</p>
                          
                
                <h3>2. How we use your information</h3>
                <div className='paragraph'>
                    We use your personal information for the following purposes:
                    <ul>
                        <li> To verify your identity when you make a payment on our website </li>
                        <li> To process payments for premium features of our telegram bots </li>
                        <li> To display the price detail in your native currency based on your IP address </li>
                        <li> To communicate with you regarding your purchases or other inquiries </li>
                        <li> To improve our website and services through website analytics data collected by third-party services like Google Analytics </li>
                    </ul>
                </div>

                <h3>3. Sharing of information</h3>
                <div className='paragraph'>
                    We do not share your personal information with third parties, except in the following circumstances:
                    <ul>
                        <li> To complete your payment transaction, we share your personal information (such as name, email address, and payment information) with payment service providers such as Razorpay, PayPal, Coinspayment, or Coinsgate </li>
                        <li> To comply with legal obligations or to protect our legal interests </li>
                    </ul>
                </div>

                <h3>4. Data security</h3>
                <p>We take reasonable steps to protect your personal information from unauthorized access, use, disclosure, or destruction. We use industry-standard security measures, including encryption and secure socket layer (SSL) technology, to protect your personal information.</p>

                <h3>5. Data retention</h3>
                <p>We retain your personal information only for as long as necessary to provide you with our services or as required by law.</p>

                <h3>6. Your rights</h3>
                <p>You have the right to access, modify, or delete your personal information at any time. To do so, please contact us at botsbyamit@gmail.com. </p>

                <h3>7. Changes to this privacy policy</h3>
                <p>We reserve the right to modify this Privacy Policy at any time. Any changes to the Privacy Policy will be reflected on this page.</p>

                <h3>8. Contact us</h3>
                <p>If you have any questions about this Privacy Policy or our privacy practices, please contact us at botsbyamit@gmail.com.</p>

                <p>By using our website and services, you consent to the collection, use, and disclosure of your personal information as outlined in this Privacy Policy.</p>

            </section>
        </div>
    )
}

export default PrivacyPolicy;