import './Details.css';
import { TextInput } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setName, setEmail, setIsEmailValid } from '../redux/slices/userDetailsSlice';

const Details = () => {
    const dispatch = useDispatch();
    const userDetails = useSelector(state => state.userDetails);
    const name = userDetails.name;
    const email = userDetails.email;
    const show_name_error = userDetails.show_name_error;
    const show_email_error = userDetails.show_email_error;
    const is_email_valid = userDetails.is_email_valid;
    const nameErrorText = show_name_error ? "Please enter your name" : null;

    let emailErrorText;
    if(show_email_error){
        emailErrorText = "Please enter your email";
    }
    else if(!is_email_valid){
        emailErrorText = "Please enter a valid email";
    }
    else{
        emailErrorText = null;
    }

    const isMobile = useMediaQuery('(max-width: 36em)');

    const validateEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    const handleNameChange = (e) => {
        const name = e.target.value;
        dispatch(setName(name));
    }
    
    const handleEmailChange = (e) => {
        const email = e.target.value;
        const isValid = validateEmail(email);
        dispatch(setEmail(email));
        dispatch(setIsEmailValid(isValid));
    }

    return (
        <div className='details'>
            <TextInput className='details_text' size={isMobile?'sm':'md'} value={name} error={nameErrorText} onChange={handleNameChange} label="Name" placeholder="Enter your name" />
            <TextInput className='details_text' size={isMobile?'sm':'md'} value={email} error={emailErrorText} onChange={handleEmailChange} label="Email" placeholder="Enter your email" type='email'/>
        </div>
    )
}

export default Details;