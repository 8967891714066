import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    price_value : 0,
    currency_symbol: "$",
    currency_code: "USD",
    product_id: "",
    product_name : "",
    plan_name : "",
    product_image_url : "",
}

const planDetailsSlice = createSlice({
    name: "planPrice",
    initialState,
    reducers: {
        setPlanDetails: (state = initialState, action) => {
            state.price_value = action.payload.price.toFixed(2);
            state.currency_symbol = action.payload.currencySymbol;
            state.currency_code = action.payload.currencyCode;
            state.product_id = action.payload.productId;
            state.product_name = action.payload.productName;
            state.plan_name = action.payload.planName;
            state.product_image_url = action.payload.productImageUrl;
        }
    }
})

export const { setPlanDetails } = planDetailsSlice.actions;
export default planDetailsSlice.reducer;