import axiosInstance from "axios";

const url = process.env.REACT_APP_ENV === "dev" ? "http://localhost:8000" : "https://checkout-api-kohl.vercel.app";

const axios = axiosInstance.create({
    baseURL: url,
    headers: {
        "Content-Type": "application/json"
    }
});


export default axios;

