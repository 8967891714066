import {PayPalButtons, FUNDING } from "@paypal/react-paypal-js";
import { createOrder, capturePayment } from "../../api/checkout";
import { showNotification } from "../notification_helper";
import { useDispatch } from "react-redux";
import { openModal, setPaymentStatus } from "../../redux/slices/paymentStatusSlice";


const PaypalCheckout = ({id,name,email,couponId, closeModal}) => {
    const FUNDING_SOURCES = [
        FUNDING.PAYPAL,
        FUNDING.PAYLATER,
        FUNDING.VENMO,
        FUNDING.CARD
    ];

    const dispatch = useDispatch();

    return (
        <div className="paypal_checkout">
            {
                FUNDING_SOURCES.map(fundingSource => {
                    return (
                        <PayPalButtons
                            fundingSource={fundingSource}
                            key={fundingSource}

                            style={{
                                layout: 'vertical',
                                shape: 'rect',
                                color: (fundingSource === FUNDING.PAYLATER) ? 'gold' : '',
                            }}

                            onCancel={(data) => {
                                closeModal();
                                dispatch(openModal());
                                dispatch(setPaymentStatus({
                                    status: "failed",
                                    title: "Payment cancelled",
                                    description: "You cancelled the payment"
                                }));
                            }}

                            createOrder={async (data, actions) => {
                                try {
                                    const res = await createOrder(id, name, email, "paypal", couponId);

                                    if(res.data.status){
                                        let result = res.data.data;
                                        return result.orderId;
                                    }
                                    else{
                                        throw new Error("Something went wrong while creating order");
                                    }
                                    // const response = await fetch("http://localhost:9597/orders", {
                                    //     method: "POST"
                                    // });

                                    // const details = await response.json();
                                    // return details.id;
                                } catch (err) {
                                    // Handle the error or display an appropriate error message to the user
                                    console.error(err);
                                    showNotification({
                                        title: "Something went wrong",
                                        description: "Something went wrong while creating order, are you connected to internet?",
                                        type: "error"
                                    })
                                }
                            }}



                            onApprove={async (data, actions) => {
                                try {
                                
                                    const res = await capturePayment(id, data.orderID);

                                    const details = res.data;
                                    // Three cases to handle:
                                    //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                                    //   (2) Other non-recoverable errors -> Show a failure message
                                    //   (3) Successful transaction -> Show confirmation or thank you message

                                    // This example reads a v2/checkout/orders capture response, propagated from the server
                                    // You could use a different API or structure for your 'orderData'
                                    const errorDetail = Array.isArray(details.details) && details.details[0];

                                    if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
                                        return actions.restart();
                                        // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
                                    }

                                    if (errorDetail) {
                                        let msg = '';
                                        msg += errorDetail.description ? ' ' + errorDetail.description : '';
                                        // msg += details.debug_id ? ' (' + details.debug_id + ')' : '';
                                        
                                        closeModal();
                                        dispatch(openModal());
                                        dispatch(setPaymentStatus({
                                            status: "failed",
                                            title: "Payment failed",
                                            description: msg
                                        }));
                                    }
                                    if(details.status === "COMPLETED"){
                                        closeModal();
                                        dispatch(openModal());
                                        dispatch(setPaymentStatus({
                                            status: "success",
                                            title: "Payment successful",
                                            description: ""
                                        }));
                                    }
                                } catch (error) { 
                                    console.error(error);
                                    closeModal();
                                    dispatch(openModal());
                                    dispatch(setPaymentStatus({
                                        status: "failed",
                                        title: "Payment failed",
                                        description: "Something went wrong while capturing payment"
                                    }));
                                }
                            }}
                        />)
                })
            }
        </div>
    );
}

export default PaypalCheckout;
