import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCheckoutDetails } from "../../api/checkout";
import { setPlanDetails } from "./planDetailsSlice";
import { setName, setEmail, setImageUrl } from "./userDetailsSlice";
import { setCoupons } from "./couponDetailsSlice";
import { login } from "./authSlice";


const initialState = {
    loading : false,
    error: null,
    checkoutDetails: null,
    code: null,
}

//  name, email, plan details, plan price, coupons

const fetchCheckoutDetails = createAsyncThunk(
    "checkoutDetails/fetchCheckoutDetails",
    async (id, thunkAPI) => {
        try{
            const response = await getCheckoutDetails(id);
            if(response.status){
                thunkAPI.dispatch(setPlanDetails(response.data));
                thunkAPI.dispatch(setName(response.data.userName));
                thunkAPI.dispatch(setEmail(response.data.userEmail));
                thunkAPI.dispatch(setImageUrl(response.data.userImageUrl));
                thunkAPI.dispatch(setCoupons(response.data.coupons));
                thunkAPI.dispatch(login({userId:response.data.userId, userImageUrl:response.data.userImageUrl}))
            }
            return response;
        }
        catch(error){
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

const checkoutDetailsSlice = createSlice({
    name: "checkoutDetails",
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchCheckoutDetails.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(fetchCheckoutDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.checkoutDetails = action.payload.data;
            state.code = action.payload.code;
        })
        builder.addCase(fetchCheckoutDetails.rejected, (state, action) => {
            console.log(action.payload);
            state.loading = false;
            state.error = action.error.message;
            state.code = action.payload.code;
        })

    }
})


export { fetchCheckoutDetails };
export default checkoutDetailsSlice.reducer;