import page_not_found from '../assets/404.json'
import Lottie from 'lottie-react';
import './NotFound.css';
import { Helmet } from 'react-helmet-async';

const NotFound = () => {
    return (
        <div className="not_found">
            <Helmet>
                <title>404 - Page Not Found</title>
            </Helmet>
            <Lottie className='not_found_animation' animationData={page_not_found} renderer='svg' autoplay={true} loop={true}/>
        </div>
    )
}


export default NotFound