import './Coupon.css';
import { Divider, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Flex } from '@mantine/core';
import { Button } from '@mantine/core';
import coupon_icon from '../../assets/coupon.png';
import { Box } from '@mantine/core';
import { TbChevronDown } from 'react-icons/tb';
import { TbChevronUp } from 'react-icons/tb';
import { useState } from 'react';
import { List } from '@mantine/core';
import { BsCheck2All } from 'react-icons/bs';
import { couponApplied } from '../../redux/slices/appliedCouponSlice';
import { useSelector, useDispatch } from 'react-redux';
import celebrateAnimation from '../../assets/celebrate.json';
import lottie from 'lottie-web';
import { showNotification } from '../notification_helper';



const Coupon = ({id, discount_percent, price_value, code, details, currency_symbol,close_modal }) => {
    const isMobile = useMediaQuery('(max-width: 36em)');
    const total_discount = ((discount_percent / 100) * price_value).toFixed(2);
    const [showDetails, setShowDetails] = useState(false);
    
    const coupon_id = useSelector(state => state.appliedCoupon.id)
    const isCouponApplied = coupon_id === id;
    const dispatch = useDispatch();
    
    const handleShowDetailsClick = () => {
        setShowDetails(!showDetails);
    }

                
    const applyCoupon = () => {
        dispatch(couponApplied({
            id,
            coupon_code: code,
            discount_percent,
            details
        }));
        showNotification({
            title: `Coupon Applied`,
            description: `🥳 You saved ${currency_symbol}${total_discount} with code ${code.toUpperCase()}`,
            type: 'success'
        })

        if(close_modal != null) {
            close_modal();
        }

        document.querySelector('.celebrate').style.display = 'block';
        
        lottie.loadAnimation({
            container: document.querySelector("#celbrate"),
            animationData: celebrateAnimation,
            renderer: "svg", // "canvas", "html"
            loop: false, // boolean
            autoplay: true, // boolean
            
        });

        // remove celebrate after animation ends
        setTimeout(() => {
            document.querySelector('.celebrate').removeChild(document.querySelector('.celebrate').lastChild);
            document.querySelector('.celebrate').style.display = 'none';
        }, 1600);

    }


    return (
        <div className="coupon">
            <Flex align='center' gap={'xs'} justify={'flex-start'}>
                <img src={coupon_icon} alt="coupon" className='coupon_icon' />
                {/* <TbDiscount2/> */}
                <Text size={isMobile ? 'sm' : 'md'} weight={700}>
                    {discount_percent}% off
                </Text>
            </Flex>

            <Text size={isMobile ? 'xs' : 'xs'} color='indigo' mx={'xl'}>
                Save {currency_symbol}{total_discount} with this code
            </Text>
            <Flex justify={'space-between'} align={'center'} mx={'xl'} >
                <Text size={isMobile ? 'xs' : 'xs'} className="coupon_code" weight={500} >
                    {code}
                </Text>

                <Flex align={'center'} className="coupon_details_btn" onClick={handleShowDetailsClick}>
                    <Text size={isMobile ? 'xs' : 'xs'} sx={{ 'flexGrow': 0 }} variant="subtle" >
                        {showDetails ? 'Hide' : 'Show'} Details
                    </Text>
                    {showDetails ? <TbChevronUp /> : <TbChevronDown />}
                </Flex>
            </Flex>

            <CouponDetails details={details} isHidden={!showDetails} />
            { isCouponApplied ? <CouponApplied /> :
                <Button onClick={applyCoupon} size={isMobile ? 'xs' : 'xs'} fullWidth color="indigo" variant="light" sx={{ 'marginTop': '.5rem', 'textTransform': 'uppercase', 'letterSpacing': '2px' }}>
                    Apply
                </Button>
            }

            <div className="celebrate" id="celbrate"></div>

        </div>
    )
}


const CouponDetails = ({ details, isHidden }) => {
    const isMobile = useMediaQuery('(max-width: 36em)');
    const className = isHidden ? 'coupon_details' : 'coupon_details show';
    return (
        <div className={className}>
            <Divider variant='dashed' />
            <List className="details_list" size={isMobile ? 'xs' : 'sm'}>
                {details.map((detail, index) => {
                    return <List.Item key={index}>{detail}</List.Item>
                })}
            </List>
        </div>
    )
}

const CouponApplied = () => {
    const isMobile = useMediaQuery('(max-width: 36em)');
    return (
        <Box component='div' className='coupon_applied'>
            <BsCheck2All />
            <Text component='span' size={isMobile ? 'xs' : 'xs'} weight={500}>
                Applied
            </Text>
        </Box>
    )
}

export default Coupon;