import './PricingCard.css'
import { Text, Divider, Button, Flex } from '@mantine/core'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { BsCheck2 } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setRedirectData, setShowLogin } from '../../redux/slices/authSlice'
import { createCheckoutSession } from '../../api/checkout'
import { showNotification } from '../notification_helper'
import CustomLoader from '../common/Loader'



const PricingCard = ({productId, id, icon, title, price, description, features, handle_checkout=true, contactUsSubject=null}) => {
    const isMobile = useMediaQuery('(max-width: 36em)');
    const navigate = useNavigate();

    const userDetails = useSelector(state => state.auth.userDetails);
    const dispatch = useDispatch();
 

    const handleClick =async ()=>{
        if(!userDetails){
            dispatch(setShowLogin(true));
            dispatch(setRedirectData({
                productId,
                planId: id
            }))
            return;
        }
        try {
            open();
            const res = await createCheckoutSession(userDetails.userId, id, productId);
            close();
            if(res.status){
                navigate(`/checkout/${res.data.id}`)
            }
            else{
                showNotification({
                    title: 'Error',
                    message: res.error,
                    type: 'error'
                })
            }
        } catch (error) {
            showNotification({
                title: 'Error',
                message: error.message,
                type: 'error'
            })
        }
    }



    const redirectToContact =()=> {
        if(contactUsSubject)
            navigate(`/contact-us?subject=${contactUsSubject}`)
        else
            navigate(`/contact-us`)
    }

    const [opened, {open,close}] = useDisclosure(false);

    return (
        <div className="pricing-card">
            <Flex gap={'xs'} align={'center'} className='pricing-card-feature'>
                {icon}
                <Text className='pricing-card-title' size={isMobile ? 'md' : 'lg'}>{title}</Text>
            </Flex>
            <Text className='pricing-card-description' size={isMobile ? 'sm' : 'md'}>{description}</Text>
            <Text className='pricing-card-price' size={isMobile ? 'lg' : 'xl'}>{price}</Text>
            <Divider />
            <div className="pricing-card-features">
                    {features.map((feature, index) => (
                        <Flex key={index} gap={'xs'} align={'center'} className='pricing-card-feature'>
                            <BsCheck2 className='pricing-card-feature-icon' />
                            <Text key={index}>{feature}</Text>
                        </Flex>
                    ))}
            </div>
            {handle_checkout?
                <Button onClick={handleClick} className='pricing-card-button' fullWidth variant='gradient' gradient={{ from: 'blue', to: 'cyan', deg: 45 }}>Buy Now</Button>
                :
                <Button onClick={redirectToContact} className='pricing-card-button' fullWidth variant='gradient' gradient={{ from: 'blue', to: 'cyan', deg: 45 }}>Get Started</Button>
            }
            <CustomLoader opened={opened} close={close}>
                <Text size='md' align='center'>Creating Checkout Session</Text>
            </CustomLoader>
        </div>
    )
}

export default PricingCard