import './HomePage.css'
import homepage_illustration from '../assets/home_img.webp'
import Header from './Header'
import { Title, Text } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

import ocr_bot from '../assets/ocr-bot.webp'
import songs_bot from '../assets/songs-bot.webp'
import translator_bot from '../assets/translator-bot.webp'
import plant_identify_bot from '../assets/plant-identify-bot.webp'
import link_expert_bot from '../assets/link-expert-bot.webp'
import torrent_bot from '../assets/torrent-bot.webp'
import BotCard from '../components/homepage/BotCard'
import {FaWrench} from 'react-icons/fa'
import {MdComputer} from 'react-icons/md'
import ServiceCard from '../components/homepage/ServiceCard'
import PricingCard from '../components/homepage/PricingCard'
import { Helmet } from 'react-helmet-async'
import { IoDiamond } from 'react-icons/io5'
import { FaCrown } from 'react-icons/fa'
import { TbDiamondsFilled } from 'react-icons/tb'
import { GiDiamonds } from 'react-icons/gi'
import {IoIosCube} from 'react-icons/io'
import { GiGoldBar } from "react-icons/gi";
import { BsXDiamondFill } from "react-icons/bs";

const bots = [
    {
        image_url: ocr_bot,
        title: 'OCR Bot',
        description: 'Extract text from images',
        username: 'the_ocr_bot'
    },
    {
        image_url: songs_bot,
        title: 'Music Downloader',
        description: 'Search and download music',
        username: 'ytsongdl_bot'
    },
    {
        image_url: translator_bot,
        title: 'Translator Bot',
        description: 'Translate text to any language',
        username: 'the_translatorBot'
    },
    {
        image_url: plant_identify_bot,
        title: 'Plant Identify Bot',
        description: 'Identify plants from images',
        username: 'plantidentifybot'
    },
    {
        image_url: link_expert_bot,
        title: 'Link Expert Bot',
        description: 'Short links, Unshort links, and more',
        username: 'link_expertbot'
    },
    {
        image_url: torrent_bot,
        title: 'Torrent Bot',
        description: 'Search torrents and get magnet links',
        username: 'the_torrent_bot'
    },
]

const HomePage = () => {
    const isMobile = useMediaQuery('(max-width: 36em)');

    const ocr_premium_plans = [
        {
            "id": "BASIC_PLAN",
            "title": "Basic Plan",
            "icon": <IoIosCube color='#db2955' size={20}/>,
            "price": "$ 2",
            "description": "Best for testing and trying out the premium features",
            "validity": 1,
            "credits": 350,
            "features": [
                "Valid for 1 month",
                "350 image credits",
                "No ads",
                "Access to all premium features",
            ]
        },
        {
            "id": "STANDARD_PLAN",
            "title": "Standard Plan",
            "icon": <BsXDiamondFill color='#ffcd35' size={20}/>,
            "price": "$ 4",
            "description": "Best for ocasional users with limited validity",
            "validity": 3,
            "credits": 1000,
            "features": [
                "Valid for 3 months",
                "1000 image credits",
                "No ads",
                "Access to all premium features",
            ]
        },
        {
            "id": "GOLDEN_PLAN",
            "title": "Golden Plan",
            "icon": <FaCrown color='#f6c342' size={20}/>,
            "price": "$ 6",
            "description": "Best for regular users with unlimited validity",
            "validity": -1,
            "credits": 2000,
            "features": [
                "Unlimited validity",
                "2000 image credits",
                "No ads",
                "Access to all premium features",
            ]
        },
        {
            "id": "PLATINUM_PLAN",
            "title": "Platinum Plan",
            "icon": <IoDiamond color='#3e8bf6' size={20}/>,
            "price": "$ 12",
            "description": "Best for heavy users with unlimited validity",
            "validity": -1,
            "credits": 5000,
            "features": [
                "Unlimited validity",
                "5000 image credits",
                "No ads",
                "Access to all premium features",
            ]
        }
    ]

    const ocrProductId = "OCR_BOT"

    return (
        <div className="homepage">
            <Helmet>
                <title>BotsByAmit - Best Telegram Bots</title>
                <meta name="description" content="BotsByAmit is a landing page for Telegram channel BotsByAmit. Users can buy premium subscriptions for the bots and also request to create a custom Telegram bot to suit their needs."/>
            </Helmet>
            <Header />
            <section className='homepage-content'>
                <div className='welcome'>
                    <Title className='welcome_text'>Enhance your productivity with our collection of 
                        {' '}<Text component="span" variant="gradient" gradient={{ from: 'blue', to: 'cyan', deg: 45 }} inherit>
                            telegram bots and services
                        </Text>{' '}
                    </Title>
                    <img src={homepage_illustration} alt="bots" />
                </div>

                <div className="our_bots">
                    <Text className='our_bots_title title_text'>Bots</Text>
                    <div className="bots">
                        {bots.map((bot, _) => <BotCard key={bot.username} {...bot} />)}
                    </div>
                </div>

                <div className='ocr_bot_pricing_section'>
                    <Text className='ocr_bot_pricing_title title_text'>OCR Bot Premium Plans</Text>

                    <div className="service_pricing_cards">
                        {ocr_premium_plans.map((plan, _) => <PricingCard key={plan.id} productId={ocrProductId} {...plan} />)}
                    </div>
                </div>

                <div className="our_services">
                    <Text className='our_services_title title_text'>Our Services</Text>
                    <div className="services">
                        <ServiceCard
                            title='Custom Telegram Bots'
                            description='We can create bots that automate tasks, provide customer support, deliver content, and more. Let us know what you need and we will make it happen.'
                            image={<FaWrench/>}
                        />
                        <ServiceCard
                            title='Websites'
                            description='We can create a stunning, responsive, and functional website that reflects your brand and meets your business goals. Whether you need a simple landing page or a complex e-commerce site, we’ve got you covered.'
                            image={<MdComputer/>}
                        />
                    </div>
                </div>


                <div className="service_pricing">
                    <Text className='service_pricing_title title_text'>Bot Services Pricing</Text>
                    <div className="service_pricing_cards">
                        <PricingCard 
                            icon={<IoIosCube color='#db2955' size={20}/>}
                            title='Basic'
                            price='₹ 3,999'
                            description='Basic bot with limited features'
                            features={[
                                'Simple telegram bot with buttons',
                                'Single functionality',
                                'API integration',
                                '3 Revisions',
                            ]}
                            handle_checkout={false}
                            contactUsSubject="Bot Service - Basic Plan"
                        />
                        <PricingCard
                            icon={<IoDiamond color='#3e8bf6' size={20}/>}
                            title='Plus'
                            price='₹ 7,999'
                            description='Bot with advanced features'
                            features={[
                                'Multi functional bot',
                                'API integration',
                                'Advanced Statistics',
                                'Unlimited Revisions',
                                'Database integration',
                            ]}
                            handle_checkout={false}
                            contactUsSubject="Bot Service - Plus Plan"
                        />
                    </div>

                </div>
                <div className="service_pricing">
                    <Text className='service_pricing_title title_text'>Website Services Pricing</Text>
                    <div className="service_pricing_cards">
                        <PricingCard 
                            icon={<IoIosCube color='#db2955' size={20}/>}
                            title='Basic'
                            price='₹ 19,999'
                            description='Basic website with limited features'
                            features={[
                                'Upto 6 pages',
                                'Responsive Design',
                                'Content upload',
                                'API integration',
                                'Database integration',
                                '5 Revisions',
                            ]}
                            handle_checkout={false}
                            contactUsSubject="Website Service - Basic Plan"
                        />
                        <PricingCard
                            icon={<IoDiamond color='#3e8bf6' size={20}/>}
                            title='Plus'
                            price='₹ 39,999'
                            description='Website with advanced features'
                            features={[
                                'Upto 20 pages',
                                'Responsive Design',
                                'Complex functionality',
                                'API integration',
                                'Database integration',
                                'Admin panel',
                                'Unlimited Revisions',
                            ]}
                            handle_checkout={false}
                            contactUsSubject="Website Service - Plus Plan"
                        />
                    </div>
                </div>
            </section>
        </div>
    )
}







export default HomePage;