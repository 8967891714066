import './SomethingWentWrong.css';
import Lottie from 'lottie-react';
import something_went_wrong from '../assets/something_went_wrong.json';
import { Button, Text } from '@mantine/core';
import { Helmet } from 'react-helmet-async';


const SomethingWentWrong = () => {
    return (
        <div className="something_went_wrong">
            <Helmet>
                <title>Something Went Wrong</title>
            </Helmet>
            <Lottie className='something_went_wrong_animation' animationData={something_went_wrong} renderer='svg' autoplay={true} loop={true}/>
            <Text className='try_reloading_text'>Try reloading the page.</Text>
            <Button className='something_went_wrong_button' onClick={() => window.location.reload()}>Reload</Button>
        </div>
    )
}


export default SomethingWentWrong
