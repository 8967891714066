import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name : "",
    email : "",
    image_url : null,
    show_name_error : false,
    show_email_error : false,
    is_email_valid : true,
}


const userDetailsSlice = createSlice({
    name: 'userDetails',
    initialState,
    reducers:{
        setName : (state = initialState,action) => {
            state.name = action.payload;
            state.show_name_error = false;
        },
        setEmail : (state = initialState,action) => {
            state.email = action.payload;
            state.show_email_error = false;
        },
        setNameError: (state = initialState,action) => {
            state.show_name_error = action.payload;
        },
        setEmailError: (state = initialState,action) => {
            state.show_email_error = action.payload;
        },
        setIsEmailValid : (state = initialState,action) => {
            state.is_email_valid = action.payload;
        },
        setImageUrl : (state = initialState,action) => {
            state.image_url = action.payload;
        }
    }
})


export const {setName, setEmail, setNameError, setEmailError, setIsEmailValid, setImageUrl} = userDetailsSlice.actions;
export default userDetailsSlice.reducer;