import './Checkout.css';
import Main from './Main';
import Header from './Header';
import { useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchCheckoutDetails } from '../redux/slices/checkoutDetailsSlice';
import { couponRemoved } from '../redux/slices/appliedCouponSlice'; 
import InvalidPaymentLink from './InvalidPaymentLink';
import { Helmet } from 'react-helmet-async';
import Loading from './Loading';
import SomethingWentWrong from './SomethingWentWrong';

function Checkout() {
	const { id } = useParams();
	
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchCheckoutDetails(id));

		return () => dispatch(couponRemoved());
	}, [dispatch,id])
	
	const checkoutDetails = useSelector(state => state.checkoutDetails);


	if(checkoutDetails.loading){
		return <Loading />
	}

	if(checkoutDetails.code === "INVALID_ID" || checkoutDetails.code === "PAYMENT_COMPLETED"){
		return <InvalidPaymentLink />
	}

	if(checkoutDetails.code === "INTERNAL_SERVER_ERROR"){
		return <SomethingWentWrong />
	}



	return (
		<div className='checkout'>
		<Header isCheckoutPage={true}/>
		<section className='checkout-content'>
			<Helmet>
			<title>Checkout | BotsByAmit</title>
			</Helmet>
			<Main />
		</section>
		</div>
	);
}

export default Checkout;
