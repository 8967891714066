import './Main.css';
import Details from './Details';
import PaymentMethods from './PaymentMethods';
import Summary from './Summary';

const Main = () => {
    return (
        <main>
            <div>
                <Details />
                <PaymentMethods />
            </div>
            <Summary />
        </main>
    )
}

export default Main;