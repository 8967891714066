import './RefundPolicy.css'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Header from './Header';
import { Helmet } from 'react-helmet-async';

const RefundPolicy = () => {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className="refund-policy-container">
            <Helmet>
                <title>Refund Policy | BotsByAmit</title>
                <meta name="description" content="Refund Policy | BotsByAmit" />
            </Helmet>
            
            <Header />
            <section className='refund-policy'>
                <h1>Refund Policy</h1>

                <p>Thank you for using BotsByAmit! We want to ensure that you are completely satisfied with your purchase of our premium telegram bots or custom bot development services. If for any reason you are not satisfied with your purchase, please contact us at botsbyamit@gmail.com and we will do our best to resolve the issue.</p>

                <h3>Refunds for Premium Subcription of our Telegram Bots</h3>
                <p>If you have purchased a premium subscription for our telegram bots and are not satisfied with the service provided, we offer a refund within 7 days of the purchase date. Please contact us at botsbyamit@gmail.com with your purchase details and the reason for the refund request. We will process your refund within 7-10 business days of receiving your request, after deducting the transaction fees.</p>

                <h3>Late or Missing Refunds</h3>
                <p>If you haven't received your refund within the expected timeframe, please first check with your bank or credit card company. It may take some time before your refund is officially posted. If you have done this and still have not received your refund, please contact us at botsbyamit@gmail.com and we will investigate the issue.</p>

                <h3>Changes to Refund Policy</h3>
                <p>We reserve the right to modify or update our refund policy at any time. Any changes will be reflected on this page.</p>

                <p>If you have any questions or concerns about our refund policy, please contact us at botsbyamit@gmail.com.</p>
            </section>
        </div>
    )
}

export default RefundPolicy;