import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    userDetails: JSON.parse(localStorage.getItem("userDetails")) || null,
    showLogin: false,
    redirectData: null
}


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers:{
        login: (state, action) => {
            state.userDetails = action.payload;
            localStorage.setItem("userDetails", JSON.stringify(action.payload));
        },
        logout: (state) => {
            state.userDetails = null;
            localStorage.removeItem("userDetails");
        },
        setShowLogin: (state, action) => {
            state.showLogin = action.payload;
            if(!action.payload) state.redirectData = null;
        },
        setRedirectData: (state, action) => {
            const {productId, planId} = action.payload;
            state.redirectData = {
                productId,
                planId
            }
        }
    }
})

export default authSlice.reducer;
export const {login,logout,setShowLogin,setRedirectData} = authSlice.actions;

