import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    status: "pending", // pending, success, failed
    title: "",
    description: "",
    showModal: false,
};

const paymentStatusSlice = createSlice({
    name: "paymentStatus",
    initialState,
    reducers: {
        setPaymentStatus: (state, action) => {
            state.status = action.payload.status;
            state.title = action.payload.title;
            state.description = action.payload.description;
        },
        openModal: (state) => {
            state.showModal = true;
        },
        closeModal: (state) => {
            state.showModal = false;
        }
    }
});

export const { setPaymentStatus,openModal,closeModal } = paymentStatusSlice.actions;
export default paymentStatusSlice.reducer;