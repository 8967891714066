import loading from '../assets/loading_animation.json';
import Lottie from 'lottie-react';
import './Loading.css';


const Loading = () => {
    return (
        <div className="loading">
            <Lottie className='loading_animation' animationData={loading} renderer='svg' autoplay={true} loop={true}/>
        </div>
    )
}


export default Loading