import { configureStore } from "@reduxjs/toolkit";
import planDetailsReducer from "./slices/planDetailsSlice";
import appliedCouponReducer from "./slices/appliedCouponSlice";
import paymentMethodReducer from "./slices/paymentMethodSlice";
import userDetailsReducer from "./slices/userDetailsSlice";
import themeReducer from "./slices/themeSlice";
import checkoutDetailsReducer from "./slices/checkoutDetailsSlice";
import couponDetailsReducer from "./slices/couponDetailsSlice";
import paymentStatusReducer from "./slices/paymentStatusSlice";
import authReducer from './slices/authSlice';

const store = configureStore({
    reducer: {
        appliedCoupon: appliedCouponReducer,
        planDetails: planDetailsReducer,
        paymentMethod: paymentMethodReducer,
        userDetails : userDetailsReducer,
        theme : themeReducer,
        checkoutDetails: checkoutDetailsReducer,
        couponDetails: couponDetailsReducer,
        paymentStatus: paymentStatusReducer,
        auth: authReducer,
    }
})

export default store;