import { Button, List, Loader, PinInput, Text } from '@mantine/core';
import classes from './Login.module.css';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { verifyPincode } from '../../api/auth';
import { login } from '../../redux/slices/authSlice';
import { showNotification } from '../notification_helper';
import CustomLoader from '../common/Loader';
import { createCheckoutSession } from '../../api/checkout';
import { useNavigate } from 'react-router-dom';


const Login = ({close}) => {
    const [pin, setPin] = useState();
    const [loading, setLoading] = useState(false);

    const redirectData = useSelector(state => state.auth.redirectData);

    const dispatch = useDispatch();

    const [showCustomLoader,setShowCustomLoader] = useState(false);
    const openModal = () => {
        setShowCustomLoader(true);
    }
    const closeModal = () => {
        setShowCustomLoader(false);
    }
    const navigate = useNavigate();

    const handleLogin = async() => {
        setLoading(true);
        try {
            const res = await verifyPincode(pin);
            if(res.status){
                dispatch(login(res.data));
                if(redirectData){
                    const {productId,planId} = redirectData;
                    try {
                        openModal();
                        const response = await createCheckoutSession(res.data.userId, planId, productId);
                        closeModal();
                        if(response.status){
                            navigate(`/checkout/${response.data.id}`)
                        }
                        else{
                            showNotification({
                                title: 'Error',
                                message: res.error,
                                type: 'error'
                            })
                        }
                    } catch (error) {
                        showNotification({
                            title: 'Error',
                            message: error.message,
                            type: 'error'
                        })
                    }
                }
                close();
            }
            else{
                showNotification({
                    title: 'Error',
                    description: res.error,
                    type: 'error'
                })
            }
        } catch (error) {
            showNotification({
                title: 'Error',
                message: error.message,
                type: 'error'
            })
        }
        setLoading(false);
    }

    const handlePinChange = (val) => {
        setPin(val);
    }

    return (
        <div className={classes.login}>
            <Text size='xl' align='center' weight={700}>Login</Text>
            <Text>Follow below steps to Login</Text>
            <List type='ordered'>
                <List.Item>Go to <a href='https://telegram.me/the_ocr_bot' target='_blank'>Ocr Bot</a> on telegram</List.Item>
                <List.Item>Send <span className={classes.command}>/login</span> command to the bot</List.Item>
                <List.Item>Copy the 6 digit code that you get from the bot</List.Item>
                <List.Item>Paste the code in the input box below</List.Item>
            </List>
            
            <PinInput onSubmit={handleLogin} type='number' value={pin} onChange={handlePinChange} className={classes.pincode} length={6}/>
            <Button variant='gradient' gradient={{ from: 'blue', to: 'cyan', deg: 45 }} onClick={handleLogin}>
                {loading ? <Loader color='gray' variant='dots'/> : 'Login'}
            </Button>
            <CustomLoader opened={showCustomLoader} close={closeModal}>
                <Text size='md' align='center'>Creating Checkout Session</Text>
            </CustomLoader>
        </div>
    )
}


export default Login;