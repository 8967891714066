import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    themeMode : localStorage.getItem("themeMode") || "light",
}

const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers : {
        toggleTheme : (state = initialState)=>{
            state.themeMode = state.themeMode === "light" ? "dark" : "light";
            localStorage.setItem("themeMode", state.themeMode);
            document.documentElement.setAttribute("theme", state.themeMode);
        }
    }
})

export default themeSlice.reducer;
export const {toggleTheme} = themeSlice.actions;