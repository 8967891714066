import './ContactUs.css'
import { useMediaQuery, useDisclosure } from '@mantine/hooks';
import Header from './Header';
import { Helmet } from 'react-helmet-async';
import {MdOutlineEmail} from 'react-icons/md'
import { Flex, TextInput,LoadingOverlay, Textarea, Button, Text } from '@mantine/core';
import { BsGithub, BsTelegram } from 'react-icons/bs';
import { useState } from 'react';
import { showNotification } from '../components/notification_helper';
import { sendMessage as sendMessageToBacked } from '../api/checkout';
import Lottie from 'lottie-react';
import loading from '../assets/loading.json';
import { useSearchParams } from 'react-router-dom';

const ContactUs = () => {
    const [searchParams] = useSearchParams("");

    const isMobile = useMediaQuery('(max-width: 36em)');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState(searchParams.get("subject") || "");
    const [message, setMessage] = useState('');
    const [nameErrorText, setNameErrorText] = useState('');
    const [emailErrorText, setEmailErrorText] = useState('');
    const [subjectErrorText, setSubjectErrorText] = useState('');
    const [messageErrorText, setMessageErrorText] = useState('');


    const [loaderVisible, { open:showLoader, close:hideLoader }] = useDisclosure(false);

    const handleNameChange = (event) => {
        setName(event.target.value);
        setNameErrorText('');
    }

    const handleEmailChange = (event) => {
        const email = event.target.value;
        const isValid = validateEmail(email);
        setEmail(email);
        setEmailErrorText('');
        if(!isValid){
            setEmailErrorText('Please enter a valid email');
        }
    }

    const handleSubjectChange = (e) => {
        setSubject(e.target.value);
        setSubjectErrorText("");
    }

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
        setMessageErrorText('');
    }

    const validateEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    const sendMessage = async () => {
        if(name === ''){
            setNameErrorText('Please enter your name');
        }
        if(email === ''){
            setEmailErrorText('Please enter your email');
        }
        else if(!validateEmail(email)){
            setEmailErrorText('Please enter a valid email');
        }
        if(message === ''){
            setMessageErrorText('Please enter your message');
        }
        if(subject === ''){
            setSubjectErrorText("Please enter the subject of the message");
        }
        if(name === '' || email === '' || message === '' || subject === ''){
            return;
        }
        try {
            showLoader();
            const res = await sendMessageToBacked(name, email, message, subject);
            hideLoader();
            if(res.status){
                showNotification({
                    title: 'Message sent successfully',
                    description: 'We will get back to you soon',
                    type: 'success'
                })
            }
            else{
                showNotification({
                    title: 'Something went wrong',
                    description: 'Message not sent',
                    type: 'error'
                })
            }
            
        } catch (error) {
            console.error(error);
            showNotification({
                title: 'Something went wrong',
                description: 'Message not sent',
                type: 'error'
            })
        }

        
        setName('');
        setEmail('');
        setMessage('');
        setSubject('');
    }

    return (
        <div>

            <Helmet>
                <title>Contact Us | BotsByAmit</title>
                <meta name="description" content="Contact Us | BotsByAmit" />
            </Helmet>

            <Header />
            <div className="contact_us_container">
                <LoadingOverlay
                    visible={loaderVisible} 
                    loader={<Lottie animationData={loading} renderer='svg' autoplay={true} loop={true}/>} 
                />
                <div className="contact_us">
                    <div className="contact_us_left_section">
                        <Text fz={isMobile?'md':'xl'} fw={'normal'}>Contact Us</Text>
                        <Text fz={isMobile?'sm':'md'} fw={'normal'} className='subheader'>
                            You can contact us by filling the form, or reach out to us on social media.
                        </Text>
                        <Flex align='center' gap={'xs'} justify={'flex-start'} className='contact-email'>
                            <MdOutlineEmail  size={20}/>
                            <Text fz={isMobile ? 'sm' : 'md'} fw={'normal'} display={'inline-block'} style={{'flexGrow':0}}>
                                <a href="mailto:botsbyamit@gmail.com">botsbyamit@gmail.com</a>
                            </Text>
                        </Flex>
                        <Flex align='center' gap={'xs'} justify={'flex-start'} className='contact-social'>
                            <a href="https://telegram.me/botsbyamit" target="_blank" rel='noreferrer'>
                                <BsTelegram size={30}/>
                            </a>
                            <a href="https://github.com/amit-y11" target="_blank" rel='noreferrer'>
                                <BsGithub size={30}/>
                            </a>
                        </Flex>
                    </div>
                    {/* <Divider orientation="vertical" variant='dashed'  /> */}
                    <div className="contact_us_right_section">
                        <Text fz={isMobile?'md':'xl'} fw={'normal'}>Send us a message</Text>
                        <TextInput mt={'md'} className='details_text' size={isMobile?'sm':'md'} value={name} error={nameErrorText} onChange={handleNameChange} label="Name" placeholder="Enter your name" />
                        <TextInput mt={'md'} className='details_text' size={isMobile?'sm':'md'} value={email} error={emailErrorText} onChange={handleEmailChange} label="Email" placeholder="Enter your email" type='email'/>
                        <TextInput mt={'md'} className='details_text' size={isMobile?'sm':'md'} value={subject} error={subjectErrorText} onChange={handleSubjectChange} label="Subject" placeholder='Enter subject of the message' />
                        <Textarea mt={'md'} minRows={4} className='details_text' size={isMobile?'sm':'md'} value={message} error={messageErrorText} onChange={handleMessageChange} label="Message" placeholder="Enter your message" />
                        <Button onClick={sendMessage} className='send_message_btn' fullWidth size={isMobile?'sm':'md'} variant="gradient" color="blue">Send message</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;
