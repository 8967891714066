import './BotCard.css'
import { Card,Text,Flex, Image } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { BsTelegram } from 'react-icons/bs'

const BotCard = ({image_url, title, description, username}) => {
    const isMobile = useMediaQuery('(max-width: 36em)');
    return (
        <Card shadow="sm" className='bot_card'>
            <Image src={image_url} width={isMobile?80:120} alt={title} />
            <BotCardBody title={title} description={description} username={username}/>
        </Card>
    )
}

const BotCardBody = ({title, description, username}) => {
    const isMobile = useMediaQuery('(max-width: 36em)');

    return (
        <div className='bot_card_body'>
            <Text size={isMobile?'md':'xl'} mt={'sm'} className='bot_title'>{title}</Text>
            <Text size={isMobile?'sm':'md'} mb={'xs'} className='bot_description'>{description}</Text>
            <Flex sx={{'alignSelf':'flex-start'}} mt={'auto'} align='center' gap={'xs'} justify={'flex-start'} className='contact-social'>
                <BsTelegram size={20}/>
                <a href={`https://telegram.me/${username}`} target="_blank" rel='noreferrer'>
                    <Text size={isMobile?'sm':'md'}>@{username}</Text>
                </a>        
            </Flex>
        </div>
    )
}


export default BotCard