import './CouponSection.css';
import { Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useState } from 'react';
import { TextInput } from '@mantine/core';
import { Button } from '@mantine/core';
import { Flex } from '@mantine/core';
import Coupon from './Coupon';
import {TbDiscount2} from 'react-icons/tb';
import { HiCheckCircle } from 'react-icons/hi';
import { useSelector, useDispatch } from 'react-redux';
import { couponRemoved, couponApplied } from '../../redux/slices/appliedCouponSlice';
import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '../notification_helper';
import { validateCoupon } from '../../api/checkout';


const CouponSection = () => {
    const isMobile = useMediaQuery('(max-width: 36em)');

    const [coupon, setCoupon] = useState('');
    const [couponErrorText, setCouponErrorText] = useState('');
    
    const handleCouponChange = (e) => {
        setCoupon(e.target.value);
    }
    const appliedCoupon = useSelector(state => state.appliedCoupon);
    const coupons = useSelector(state => state.couponDetails.coupons);
    const planDetails = useSelector(state => state.planDetails);

    const price_value = planDetails.price_value;
    const currency_symbol = planDetails.currency_symbol;

    let discount_percent;
    const isCouponApplied = appliedCoupon.coupon_code != null;
    const dispatch = useDispatch();

    const handleCouponSubmit = async () => {
        if(coupon === '') {
            setCouponErrorText('Please enter a coupon code');
            return;
        }
        try {
            const result = await validateCoupon(coupon, planDetails.product_id);
            if(result.status){
                // coupon is valid
                dispatch(couponApplied({
                    id : result.data.id,
                    coupon_code: result.data.code,
                    discount_percent: result.data.discount_percent,
                    details: result.data.details
                }));
                discount_percent = result.data.discount_percent;
                const total_discount = ((discount_percent / 100) * price_value).toFixed(2);
                showNotification({
                    title: `Coupon Applied`,
                    description: `🥳 You saved ${currency_symbol}${total_discount} with code ${coupon.toUpperCase()}`,
                    type: 'success'
                })
            }
            else{
                showNotification({
                    title: `Coupon Invalid`,
                    description: `Coupon code ${coupon.toUpperCase()} is invalid`,
                    type: 'warning'
                })
            }
        } catch (error) {
            showNotification({
                title: `Coupon Invalid`,
                description: `Coupon code ${coupon.toUpperCase()} is invalid`,
                type: 'warning'
            })
        }
        
    }

    const handleKeydown = (event) => {
        if(event.key === 'Enter'){
            handleCouponSubmit();
        }
    }

    const removeCoupon = () => {
        dispatch(couponRemoved());
        showNotification({
            title: `Coupon Removed`,
            description: `Coupon code ${appliedCoupon.coupon_code.toUpperCase()} removed successfully`,
            type: 'delete'
        })
    }

    const [opened, { open, close }] = useDisclosure(false);


    return (
        <div className='coupon_section'>
            <TextInput 
                className='coupon_input'
                size={isMobile ? 'sm' : 'md'}
                value={coupon} 
                error={couponErrorText} 
                onChange={handleCouponChange} 
                onKeyDown={handleKeydown}
                placeholder="Enter a coupon code" 
                rightSection={<Button size={isMobile ? 'xs' : 'xs'} style={{'textTransform':'uppercase','letterSpacing':'1px'}} color="indigo" variant="light" onClick={handleCouponSubmit}>Apply</Button>}
                rightSectionWidth={isMobile ? '75px' : '80px'}
                spellCheck={false}
            />
            {coupons.length > 0 ? 
                <>
                    <Flex align='center' gap={'xs'} justify={'flex-start'} className='available_coupons'>
                        <Text size={isMobile ? 'xs' : 'sm'} component='span'> Available Coupons </Text>
                        <TbDiscount2 color='var(--primary-color)'/>
                        <Text onClick={open} size={isMobile ? 'xs' : 'sm'} color='indigo' className='viewall_coupon'> view all [{coupons.length}] </Text>
                    </Flex>

                    <Modal opened={opened}  onClose={close} centered title="Available Coupons" size='md' >
                            {coupons.map((coupon) => (
                                <Coupon key={coupon.id} close_modal={close} id={coupon.id} code={coupon.code} discount_percent={coupon.discount_percent} price_value={price_value} details={coupon.details} currency_symbol={currency_symbol}/>
                            ))}
                    </Modal>
                    {isCouponApplied? 
                        <Coupon id={appliedCoupon.id} code={appliedCoupon.coupon_code} discount_percent={appliedCoupon.discount_percent} price_value={price_value} details={appliedCoupon.details} currency_symbol={currency_symbol}/>
                    :
                        <Coupon id={coupons[0].id} code={coupons[0].code} discount_percent={coupons[0].discount_percent} price_value={price_value} details={coupons[0].details} currency_symbol={currency_symbol}/>
                    }
                </>
            : <div className="sizedBox"></div>}
            {isCouponApplied?
                <Flex align='center' gap={'xs'} justify={'flex-start'} className='code_applied'>
                    <HiCheckCircle size={'30px'} color='var(--success-color)'/>
                    <Text component='span' size={isMobile ? 'xs' : 'sm'} > {appliedCoupon.coupon_code.toUpperCase()} Code Applied </Text>
                    <Text size={isMobile ? 'xs' : 'sm'} color='red' className='viewall_coupon' onClick={removeCoupon}> Remove </Text>
                </Flex>
            : null }
        </div>
    )
}

export default CouponSection;