import './Header.css';
import logo from '../assets/logo.webp';
import { BsSun } from 'react-icons/bs';
import { RiMoonClearLine } from 'react-icons/ri';
import { Button, Menu, Modal, Tooltip } from '@mantine/core';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { toggleTheme } from '../redux/slices/themeSlice';
import { Avatar } from '@mantine/core';
import { Skeleton } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import Login from '../components/auth/Login';
import { logout, setShowLogin } from '../redux/slices/authSlice';
import { IoLogOut } from 'react-icons/io5';

const Header = ({isCheckoutPage = false}) => {
    const themeMode = useSelector(state => state.theme.themeMode);
    const userDetails = useSelector(state => state.auth.userDetails);
    const isLoading = useSelector(state => state.checkoutDetails.loading);
    const dispatch = useDispatch();
    const toggleThemeMode = () => {
        dispatch(toggleTheme());
    }

    const toolTipLabel = themeMode === 'light' ? 'Dark Theme' : 'Light Theme';

    const showLogin = useSelector(state => state.auth.showLogin)

    const handleLogout = () => {
        dispatch(logout());
    }

    const openLogin = () => {
        dispatch(setShowLogin(true));
    }

    const closeLogin = () => {
        dispatch(setShowLogin(false));
    }

    return (
        <header>
            <div className="brand">
                <Link to="/"><img src={logo} alt="botsbyamit" className='brand_logo' /></Link>
                <Link to="/"><span className='brand_name'>BotsByAmit</span></Link>
            </div>
            <div className="right_section">
                <Tooltip label={toolTipLabel} position="bottom" withArrow>
                    <div className="theme" onClick={toggleThemeMode}>
                        {themeMode === 'light' ? <RiMoonClearLine size={"20px"}/> : <BsSun size={"20px"}/>}
                    </div>
                </Tooltip>
                {userDetails ?
                    <Menu shadow='md' width={150} offset={12} withArrow trigger='hover' openDelay={100} closeDelay={400}>
                        <Menu.Target>
                            <Avatar size="md" radius="xl" src={userDetails?.userImageUrl} />
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item color="red" icon={<IoLogOut size={'20px'}/>} onClick={handleLogout}>
                                Logout
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                :
                    <Button variant='outline' color='cyan' onClick={openLogin}>Login</Button>
                }
            </div>
            <Modal opened={showLogin} onClose={closeLogin}  withCloseButton={false} centered>
                <Login close={closeLogin}/>
            </Modal>
        </header>
    )
}

export default Header;