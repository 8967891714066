import './InvalidPaymentLink.css';
import Lottie from 'lottie-react';
import warning from '../assets/warning.json';
import { Alert } from '@mantine/core';
import { Helmet } from 'react-helmet-async';


const InvalidPaymentLink = () => {
    return (
        <div className="invalid-payment-link">
            <Helmet>
                <title>Invalid Payment Link</title>
            </Helmet>
            <Lottie className='warning-animation' animationData={warning} renderer='svg' autoplay={true} loop={true}/>
            <Alert title="Payment Link Invalid / Expired" color="yellow" variant='outline' radius={'md'}>
                Payment link is invalid or expired, please generate a new payment link.
            </Alert>
        </div>
    )
}


export default InvalidPaymentLink
