import './Summary.css';
import { Text, Button, Divider, Modal, Flex, LoadingOverlay } from '@mantine/core';
import { useMediaQuery, useDisclosure } from '@mantine/hooks';
import PlanDetails from '../components/summary/PlanDetails';
import CouponSection from '../components/summary/CouponSection';
import PriceDetails from '../components/summary/PriceDetails';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setError } from '../redux/slices/paymentMethodSlice';
import { setNameError, setEmailError } from '../redux/slices/userDetailsSlice';
import { createOrder,verifyPayment } from '../api/checkout';
import { useParams } from 'react-router-dom';
import PaypalCheckout from '../components/summary/PaypalCheckout';
import CryptoOptions from '../components/summary/CryptoOptions';
import { showNotification } from '../components/notification_helper';
import Lottie from 'lottie-react';
import paymentDone from '../assets/done.json';
import paymentFailed from '../assets/failed.json';
import loading from '../assets/loading.json';
import { closeModal, openModal, setPaymentStatus } from '../redux/slices/paymentStatusSlice';


const Summary = () => {
    const { id } = useParams();
    const isMobile = useMediaQuery('(max-width: 36em)');
    
    const dispatch = useDispatch();
    const current_payment_method = useSelector(state => state.paymentMethod.current_payment_method);
    const userDetails = useSelector(state => state.userDetails);
    const planDetails = useSelector(state => state.planDetails);
    const appliedCoupon = useSelector(state => state.appliedCoupon);
    const name = userDetails.name;
    const email = userDetails.email;

    const [loaderVisible, { open:showLoader, close:hideLoader }] = useDisclosure(false);

    
    const TEST_KEY = "rzp_test_X2gHNT5HBCF8Mq";
    const PROD_KEY = "rzp_live_rVV11uFw6KiJOw";

    const loadRazorpay = (orderData) => {
        if(window.Razorpay === undefined){
            showNotification({
                title: "Something went wrong",
                description: "Something went wrong while loading razorpay, are you connected to internet?",
                type: "error"
            })
            return;
        }
        if(!orderData){
            showNotification({
                title: "Something went wrong",
                description: "Something went wrong while creating order",
                type: "error"
            })
            return;
        }

        const options = {
            "key": process.env.REACT_APP_ENV === "dev"? TEST_KEY : PROD_KEY, 
            "amount": orderData.amount,
            "currency": orderData.currency,
            "name": "BotsByAmit",
            "description": `${planDetails.product_name} - ${planDetails.plan_name}`,
            "image": "https://ucarecdn.com/d9d97f23-5adc-465e-9ff7-7972cfeabd1c/",
            "order_id": orderData.orderId, 
            "handler": async function (response){
                try{
                    dispatch(openModal());
                    dispatch(setPaymentStatus({
                        status: "pending",
                        title: "Verifying payment",
                        description : ""
                    }))
                    const result = await verifyPayment(id,response.razorpay_order_id, response.razorpay_payment_id, response.razorpay_signature);
        
                    if(result.data.status){
                        dispatch(setPaymentStatus({
                            status: "success",
                            title: "Payment Successful",
                            description : ""
                        }))
                    }
                    else{
                        dispatch(setPaymentStatus({
                            status: "failed",
                            title: "Payment Failed",
                            description : "Payment verification failed, please try again"
                        }))
                    }
                }
                catch(err){
                    showNotification({
                        title: "Something went wrong",
                        description: "Something went wrong while verifying payment",
                        type: "error"
                    })
                }
                
            },
            "prefill": { 
                "name": userDetails.name,
                "email": userDetails.email,
                "phone": null
            },
            "theme": {
                "color" : "#4c6ef5"
            }
        };
        // eslint-disable-next-line
        const rzp = new Razorpay(options);
        rzp.on('payment.failed', function (response){
            // alert(response.error.code);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
        });
        rzp.open();
    }


    const continueToPayment = async (e) => {
        if(!current_payment_method){
            dispatch(setError(true));
        }
        if(name === ""){
            dispatch(setNameError(true));
        }
        if(email === ""){
            dispatch(setEmailError(true));
        }
        if(name === "" || email === "" || !current_payment_method){
            return;
        }

        if(current_payment_method === 'razorpay'){
            try{
                showLoader();
                const res = await createOrder(id, userDetails.name, userDetails.email, "razorpay", appliedCoupon.id);
                hideLoader();
                if(res.data.status){
                    loadRazorpay(res.data.data);
                }
                else{
                    showNotification({
                        title: "Something went wrong",
                        description: "Something went wrong while creating order, are you connected to internet?",
                        type: "error"
                    })
                }
            }
            catch(err){
                console.log(err);
                showNotification({
                    title: "Something went wrong",
                    description: "Something went wrong while creating order, are you connected to internet?",
                    type: "error"
                })
            }
        }
        else if(current_payment_method === 'paypal'){
            open();
        }
        else{
            openCrypto();
        }
    }


    const themeMode = useSelector(state => state.theme.themeMode);
    const buttonColor = themeMode === 'dark' ? 'indigo.9' : 'indigo.5';

    const [opened, {open,close}] = useDisclosure(false);
    const [cyptoOpened, {open: openCrypto,close: closeCrypto}] = useDisclosure(false);

    const paymentStatus = useSelector(state => state.paymentStatus);


    return (
        <div className='summary'>
            <LoadingOverlay
                visible={loaderVisible} 
                loader={<Lottie animationData={loading} renderer='svg' autoplay={true} loop={true}/>} 
            />
            <Text fz={isMobile ? 'md' : 'lg'} fw={'normal'}> Summary </Text>
            <PlanDetails />
            <CouponSection />
            <Divider variant='dashed' color='#a6a7ab'/>
            <PriceDetails />
            <Button color={buttonColor} variant="light" fullWidth onClick={continueToPayment}>Continue to Payment</Button>
            
            <Modal opened={opened} onClose={close} centered>
                <PaypalCheckout id={id} name={name} email={email} couponId={appliedCoupon.id} closeModal={close}/>
            </Modal>
            <Modal title="Select Cryptocurrency" opened={cyptoOpened} onClose={closeCrypto} centered>
                <CryptoOptions />
            </Modal>
            <Modal opened={paymentStatus.showModal} onClose={() => dispatch(closeModal())} centered withCloseButton={paymentStatus.status !== "pending"} closeOnClickOutside={paymentStatus.status !== "pending"} closeOnEscape={paymentStatus.status !== "pending"}>
                <PaymentStatus isMobile={isMobile}/>
            </Modal>
        </div>
    );
}


const PaymentStatus = ({isMobile}) => {
    
    const paymentStatus = useSelector(state => state.paymentStatus);

    const getAnimationData = (status) => {
        switch (status) {
            case 'success':
                return paymentDone;
            case 'failed':
                return paymentFailed;
            default:
                return loading;
        }
    }

    
    return (
        <Flex direction={'column'} align={'center'} justify={'center'} gap={'xs'} className='payment-status'>
            <Lottie
                animationData={getAnimationData(paymentStatus.status)}
                renderer='svg' 
                autoplay={true}
                loop={true}
            />
            <Text fz={isMobile ? 'md' : 'lg'} fw={'bold'}>{paymentStatus.title}</Text>
            <Text fz={isMobile ? 'sm' : 'md'} className='subheader' fw={'normal'}>{paymentStatus.description}</Text>
        </Flex>
    );
}

export default Summary;