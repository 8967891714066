import './PlanDetails.css';
import { Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Avatar } from '@mantine/core';
import { useSelector } from 'react-redux';

const PlanDetails = () => {
    const isMobile = useMediaQuery('(max-width: 36em)');
    const planDetails = useSelector(state => state.planDetails);

    return (
        <div className='plan_details'>
            <Avatar
                src={planDetails.product_image_url}
                size={isMobile?'md':'lg'}
                radius={'xl'}
                alt={planDetails.product_name}
                style={{'alignSelf':'start'}}
            />
            <div>
                <Text fz={isMobile?'sm':'md'} transform='capitalize' fw={'normal'}>{planDetails.plan_name}</Text>
                <Text className='subheader' fz={isMobile?'xs':'xs'} transform='uppercase' fw={'normal'}>{planDetails.product_name}</Text>
            </div>
        </div>
    );
}

export default PlanDetails;