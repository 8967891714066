import './PaymentMethod.css';
import { Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setPaymentMethod } from '../../redux/slices/paymentMethodSlice';

const PaymentMethod = ({label,icon,value}) => {
    const isMobile = useMediaQuery('(max-width: 36em)');

    const dispatch = useDispatch();
    const isChecked = useSelector(state => state.paymentMethod.current_payment_method) === value;
    const className = isChecked ? 'payment_method payment_method_active' : 'payment_method';

    const handleClick = () => {
        dispatch(setPaymentMethod(value));
    }
    return (
        <div className={className} onClick={handleClick} tabIndex={0}>
            <div>
                <input type='radio' id={`${value}_payment`} name='payment_method' checked={isChecked} onChange={handleClick} />
                <label htmlFor={`${value}_payment`}><Text fz={isMobile?'sm':'md'} fw={'normal'}>{label}</Text></label>
            </div>
           <img src={icon} alt={value} />
        </div>
    )
}

export default PaymentMethod;