import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: null,
    coupon_code: null,
    discount_percent: null,
}

const appliedCouponSlice = createSlice({
    name: "coupon",
    initialState,
    reducers: {
        couponApplied: (state = initialState, action) => {
            state.id = action.payload.id;
            state.coupon_code = action.payload.coupon_code;
            state.discount_percent = action.payload.discount_percent;
            state.details = action.payload.details;
        },
        couponRemoved: (state = initialState) => {
            state.id = null;
            state.coupon_code = null;
            state.discount_percent = null;
        }
    }
})

export const { couponApplied, couponRemoved } = appliedCouponSlice.actions;
export default appliedCouponSlice.reducer;