import { Text,Flex,Button,LoadingOverlay } from "@mantine/core";
import { useMediaQuery,useDisclosure } from "@mantine/hooks";
import bitcoin from '../../assets/btc.svg';
import ethereum from '../../assets/eth.svg';
import litecoin from '../../assets/ltc.svg';
import tether from '../../assets/usdt.svg';
import './CryptoOptions.css';
import { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { createOrder } from "../../api/checkout";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { showNotification } from "../notification_helper";
import loading from '../../assets/loading.json';
import Lottie from 'lottie-react';



const CryptoOptions = () => {
    const { id } = useParams();
    const [selectedCoin,setSelectedCoin] = useState(null);
    const userDetails = useSelector(state => state.userDetails);
    const appliedCoupon = useSelector(state => state.appliedCoupon);

    const [loaderVisible, { open:showLoader, close:hideLoader }] = useDisclosure(false);


    const continueToPayment = async () => {
        try{
            showLoader();
            const res = await createOrder(id, userDetails.name, userDetails.email, "crypto", appliedCoupon.id, selectedCoin);
            hideLoader();
            if(res.data.status){
                window.open(res.data.data.checkoutUrl, "_self");
            }
            else{
                showNotification({
                    title: "Something went wrong",
                    description: "Something went wrong while creating order, are you connected to internet?",
                    type: "error"
                })
            }
        }
        catch(err){
            console.log(err);
            showNotification({
                title: "Something went wrong",
                description: "Something went wrong while creating order",
                type: "error"
            })
        }

    }


    return (
        <div className="crypto-options">
            <LoadingOverlay
                visible={loaderVisible} 
                loader={<Lottie animationData={loading} renderer='svg' autoplay={true} loop={true}/>} 
            />
            <Coins imgUrl={bitcoin} coinName="Bitcoin" code={'BTC'} selectedCoin={{value:selectedCoin,setCoin:setSelectedCoin}} />
            <Coins imgUrl={tether} coinName="Tether" code={'USDT'} selectedCoin={{value:selectedCoin,setCoin:setSelectedCoin}}/>
            <Coins imgUrl={ethereum} coinName="Ethereum" code={'ETH'} selectedCoin={{value:selectedCoin,setCoin:setSelectedCoin}}/>
            <Coins imgUrl={litecoin} coinName="Litecoin" code={'LTC'} selectedCoin={{value:selectedCoin,setCoin:setSelectedCoin}}/>
            <Button onClick={continueToPayment} variant="filled" color="blue" size={'sm'} fullWidth disabled={selectedCoin === null}> Continue to Payment </Button>
        </div>
    )
}

const Coins = ({imgUrl,coinName,code, selectedCoin}) => {
    const isMobile = useMediaQuery('(max-width: 36em)');

    const setCoin = () => {
        selectedCoin.setCoin(code);
    }

    const isSelected = selectedCoin.value === code;
    const className = isSelected ? "coins coin-selected" : "coins";

    return (
        <Flex gap={'sm'} align={'center'} className={className} onClick={setCoin}>
            <img className="coin-image" src={imgUrl} alt={coinName} />
            <Flex direction={'column'}>
                <Text className="coin-name" fz={isMobile ? 'sm' : 'md'} fw={'normal'}> {coinName} </Text>
                <Text className="coin-code" fz={isMobile ? 'xs' : 'xs'} fw={'lighter'}> {code} </Text>
            </Flex>
            {isSelected ? <FaCheckCircle className="coin-selected-icon" color="var(--accent-color)" size={'20'}/> : null}
        </Flex>
    )
}


export default CryptoOptions;