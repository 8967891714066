import './App.css';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import Checkout from './containers/Checkout';
import { BrowserRouter,Routes, Route } from 'react-router-dom';
import HomePage from './containers/HomePage';
import Footer from './containers/Footer';
import Terms from './containers/Terms';
import PrivacyPolicy from './containers/PrivacyPolicy';
import RefundPolicy from './containers/RefundPolicy';
import ContactUs from './containers/ContactUs';
import { HelmetProvider } from 'react-helmet-async';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import NotFound from './containers/NotFound';
import { Outlet } from 'react-router-dom';

const PageLayout = () => {
  return (
    <>
      <Outlet />
      <Footer />
    </>
  )
}


function App() {
  const currentTheme = useSelector(state => state.theme.themeMode);
  
  useEffect(() => {
    document.documentElement.setAttribute('theme', currentTheme);
  }, [currentTheme])

  
  const initialOptions = {
    "client-id": process.env.REACT_APP_ENV === "dev"?"AZxxj1ZTZvntKpw6hWveFsxh2jnatAuKsz841MQOUrEYRUCgxhynWPNkoIsK-FZHqrzvqVxa1_oU1MJA":"AWH2bhKiWymn1lslNOLFBJLj_mnKzEHzMbCVV8Y_Zc0Jy6uWAlT6Wdmx8Adatp0308FGbx923-3fayUw",
    "enable-funding": "paylater,venmo",
    "components": "buttons",
  }

  return (
    <div className='App'>
      <HelmetProvider>
      <MantineProvider theme={{'fontFamily': 'Product Sans, sans-serif','colorScheme': currentTheme }}>
        <PayPalScriptProvider options={initialOptions}>
          <BrowserRouter>
            <Routes>
              <Route element={<PageLayout/>}>
                <Route path='/' element={<HomePage/>} />
                <Route path='/checkout/:id' element={<Checkout/>} />
                {/* <Route path='/checkout/' element={} /> */}
                <Route path='/terms' element={<Terms/>} />
                <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
                <Route path='/refund-policy' element={<RefundPolicy/>} />
                <Route path='/contact-us' element={<ContactUs/>} />
              </Route>
              {/* 404 route*/}
              <Route path='*' element={<NotFound/>} />
            </Routes>
          </BrowserRouter>
        </PayPalScriptProvider>
        <Notifications position='top-center' />
      </MantineProvider>
      </HelmetProvider>
    </div>
  );
}



export default App;
