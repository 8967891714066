import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    current_payment_method : null,
    showError: false
}


const paymentMethodSlice = createSlice({
    name : "paymentMethod",
    initialState,
    reducers:{
        setPaymentMethod : (state = initialState,action)=>{
            state.current_payment_method = action.payload;
            state.showError = false;
        },
        setError : (state = initialState,action)=>{
            state.showError = action.payload;
        }
    }

})

export const {setPaymentMethod, setError} = paymentMethodSlice.actions;
export default paymentMethodSlice.reducer;