import './Terms.css'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Header from './Header';
import { Helmet } from 'react-helmet-async';


const Terms = () => {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className="terms-container">
            <Helmet>
                <title>Terms of Service | BotsByAmit</title>
                <meta name="description" content="Terms of Service | BotsByAmit" />
            </Helmet>
            <Header />
            <section className='terms'>
                <h1>Terms and Conditions</h1>

                <p>This website is operated by BotsByAmit. Throughout the site, the terms “we”, “us” and “our” refer to BotsByAmit. BotsByAmit offers this website, including all information, tools, and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies, and notices stated here.</p>
                <p>By visiting our site and/or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/or contributors of content.</p>
                <p>Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.</p>
                <p>Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.</p>

                <h3>1. Use of Website</h3> 
                <p>You must be at least 18 years of age to use our website. You agree to use our website only for lawful purposes and in compliance with all applicable laws and regulations. You may not use our website in any way that could damage, disable, overburden, or impair our servers or networks.</p>

                <h3>2. Premium Features</h3>
                <p>Our website provides access to premium features of our telegram bots. The terms of payment for these features are determined by third-party payment service providers, such as Razorpay, PayPal, Coinspayment, or Coinsgate. You agree to comply with the terms of these providers when making payments for premium features.</p>

                <h3>3. Customized Bots</h3>
                <p>We also offer services for creating customized telegram bots. You agree to provide accurate information and follow our guidelines for creating customized bots. We reserve the right to refuse service to anyone who violates our guidelines or engages in illegal or unethical activities.</p>

                <h3>4. Intellectual Property</h3> 
                <p>All content and images on our website are owned by BotsByAmit unless otherwise specified. Some images or icons may be used from third-party providers, with proper attribution. You may not copy, reproduce, distribute, or modify any content or images on our website without our prior written consent.</p>

                <h3>5. Disclaimer of Warranties</h3>
                <p>Our services are provided "as is" and without warranties of any kind, whether express or implied. We make no warranty that our services will be uninterrupted, error-free, or meet your specific requirements.</p>

                <h3>6. Limitation of Liability</h3>
                <p>In no event shall BotsByAmit be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of our website or services.</p>

                <h3>7. Dispute Resolution</h3>
                <p>If you have any disputes or concerns regarding our website or services, please contact us at botsbyamit@gmail.com. We will make every effort to resolve your concerns in a timely and fair manner.</p>

                <h3>8. Termination and Cancellation</h3> 
                <p>You may cancel or terminate your account by contacting us at botsbyamit@gmail.com. Your data will be deleted from our database upon termination of your account.</p>

                <p>By using our website and services, you agree to comply with these Terms and Conditions. We reserve the right to modify or update these Terms and Conditions at any time, so please review them periodically. If you have any questions or concerns regarding these Terms and Conditions, please contact us at botsbyamit@gmail.com.</p>

            </section>
        </div>
    ) 
}

export default Terms

