import './PaymentMethods.css';
import { Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { MdLock } from 'react-icons/md';
import { Flex } from '@mantine/core';
import PaymentMethod from '../components/payment_methods/PaymentMethod';
// import razorpayIcon from '../assets/razorpay-icon.svg';
// import razorpayDarkIcon from '../assets/razorpay.png';
import paypalIcon from '../assets/paypal-icon.svg';
import paypalLogo from '../assets/paypal-logo.png';
// import razorpayLogo from '../assets/razorpay-logo.svg';
import cryptoIcon from '../assets/crypto-icon.png';
import cryptoLogo from '../assets/bitcoin-logo.svg';
import { useSelector } from 'react-redux';


const PaymentMethods = () => {
    const isMobile = useMediaQuery('(max-width: 36em)');
    const isSmallScreen = useMediaQuery('(max-width: 25em)');
    const showError = useSelector(state => state.paymentMethod.showError);
    // const themeMode = useSelector(state => state.theme.themeMode);
    // const isDarkMode = themeMode === 'dark';
    // const razorpay__icon = isDarkMode ? razorpayDarkIcon : razorpayIcon;
    const className = showError?'payment_methods error':'payment_methods';
    // const razorpay_icon = isSmallScreen ? razorpayLogo : razorpay__icon;
    const paypal_icon = isSmallScreen ? paypalLogo : paypalIcon;
    const crypto_icon = isSmallScreen ? cryptoLogo : cryptoIcon;
    return (
        <div className={className}>
            <Text fz={isMobile ? 'md' : 'lg'} fw={'normal'}>
                Select Payment Methods
            </Text>
            
            <Flex align='center' gap={'xs'} justify={'flex-start'}>
                <Text fz={isMobile ? 'sm' : 'md'} fw={'normal'} display={'inline-block'} style={{'flexGrow':0}} className='subheader'>
                    All transactions are secured and encrypted 
                </Text>
                
                <MdLock color='#4caf50'/>
            </Flex>
            

            <div className="payment_methods_list"> 
                {/* <PaymentMethod
                    value={"razorpay"}
                    label="Indian Cards / Netbanking / UPI"
                    icon={razorpay_icon}
                /> */}
                <PaymentMethod 
                    value={"paypal"}
                    label="International Credit / Debit cards" 
                    icon={paypal_icon} 
                    />
                <PaymentMethod 
                    value={"crypto"}
                    label="BTC / USDT / Other Crypto" 
                    icon={crypto_icon} 
                />
            </div>
            {showError?
                <Text fz={isMobile?'xs':'sm'} fw={"normal"} color='red'>
                    Please select one payment method
                </Text>
            :null}
        </div>
    )
}

export default PaymentMethods;