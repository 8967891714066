import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    coupons : [],
}


const couponDetailsSlice = createSlice({
    name: 'coupons',
    initialState,
    reducers:{
        setCoupons : (state = initialState,action) => {
            state.coupons = action.payload;
        },
    }
})


export const {setCoupons} = couponDetailsSlice.actions;
export default couponDetailsSlice.reducer;