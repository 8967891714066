import { notifications } from "@mantine/notifications";
import { BsCheck2All } from "react-icons/bs";
import { IoWarningOutline } from "react-icons/io5";
import { IoInformationCircleOutline } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { MdDelete } from 'react-icons/md';
import { MdUpdate } from 'react-icons/md';


// message: string
// type: "success" | "error" | "info" | "warning"
export const showNotification = ({title,description,type}) => {
    const icon = {
        success: <BsCheck2All />,
        error: <RxCross2/>,
        info: <IoInformationCircleOutline />,
        warning: <IoWarningOutline />,
        delete: <MdDelete />,
        update: <MdUpdate />,
    };
    
    notifications.show({
        title: title,
        message: description,
        icon: icon[type],
        color: getColor(type),
        autoClose: 5000,
        radius: "lg",
        withBorder: true,
    });
}


const getColor = (type) => {
    switch (type) {
        case "success":
            return "green";
        case "error":
            return "red";
        case "info":
            return "cyan";
        case "warning":
            return "yellow";
        case "delete":
            return "red";
        case "update":
            return "lime";
        default:
            return "cyan";
    }
}